<template>
    <div> 
      <!-- Dashboard content -->
      <div class="row">
        <div class="col-md-6 mb-2">
          <label for="">Data Kajian</label>
          <v-select @input="changeKajianAwal($event)" placeholder="Pilih Data Kajian" v-model="row.arm_id" :options="historyKajianPerawat" label="text" :reduce="v=>v.value"></v-select>
        </div>
      </div>
      <div class="row" v-if="row.arm_notes">
        <div class="col-md-12">
          <b-alert show fade>{{row.arm_notes||"-"}}</b-alert>
        </div>
      </div>
      
      <div class="row">
          <div class="col-md-6">
            <b-alert show fade>Nama Inputer : {{row.bu_full_name||"-"}}</b-alert>
          </div>
      </div>
      
      <ul class="nav nav-tabs nav-tabs-bottom nav-justified">
        <li class="nav-item"><a href="javascript:;" @click="changeTab(1)" :class="activeTab == 1 ? 'nav-link active' : 'nav-link'" data-toggle="tab">Kajian Awal</a></li>
        <li class="nav-item"><a href="javascript:;" @click="changeTab(2)" :class="activeTab == 2 ? 'nav-link active' : 'nav-link'" data-toggle="tab">Kajian Nyeri</a></li>
        <li class="nav-item"><a href="javascript:;" @click="changeTab(3)" :class="activeTab == 3 ? 'nav-link active' : 'nav-link'" data-toggle="tab">Risiko Jatuh </a></li>
        <li class="nav-item"><a href="javascript:;" @click="changeTab(4)" :class="activeTab == 4 ? 'nav-link active' : 'nav-link'" data-toggle="tab">Skrining Gizi</a></li>
        <li class="nav-item"><a href="javascript:;" @click="changeTab(5)" :class="activeTab == 5 ? 'nav-link active' : 'nav-link'" data-toggle="tab">Asuhan Keperawatan</a>
        </li>
      </ul>
      <div class="tab-content card-body pt-0">
        <div :class="activeTab == 1 ? 'tab-pane fade show active' : 'tab-pane'" id="kajian-awal">
          <div class="card">
            <div class="card-header bg_head_panel">
              <h5 class="card-title font-weight-semibold">Informasi Pasien</h5>
            </div>
            <div class="card-body p-3">
              <table class="table table-bordered">
                <tbody>
                  <tr>
                    <td>
                      <div class="result_tab">
                        <h4>No. Rekam Medis</h4>
                        <p>{{row.ap_code||"-"}}</p>
                      </div>
                    </td>
                    <td>
                      <div class="result_tab">
                        <h4>Nama Pasien</h4>
                        <p>{{row.ap_fullname||"-"}}</p>
                      </div>
                    </td>
                    <td>
                      <div class="result_tab">
                        <h4>Tanggal Lahir</h4>
                        <p>{{row.ap_dob | moment("DD MMM YYYY")}}</p>
                      </div>
                    </td>
                    <td>
                      <div class="result_tab">
                        <h4>Jenis Kelamin</h4>
                        <p>{{row.cg_label||"-"}}</p>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="table-responsive">
            <table class="table table-bordered">
              <tbody>
                <tr>
                  <td>
                    <div class="result_tab">
                      <h4>Keluhan Utama</h4>
                      <p>{{row.aka_keluhan_utama||"-"}}</p>
                    </div>
                  </td>
                  <td>
                    <div class="result_tab">
                      <h4>Riwayat Penyakit</h4>
                      <p>{{row.aka_riwayat_penyakit||"-"}}</p>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="form-row mt-2">
            <div class="col-md-6">
              <div class="card mb-0">
                <div class="card-header bg_head_panel">
                  <h6 class="card-title font-weight-semibold">Alergi</h6>
                </div>
                <div class="card-body p-3">
                  <div class="form-row" v-if="row.aka_has_alergi == 'Y'">
                    <div class="col-md-12">
                      <div class="table-responsive">
                        <table class="table table-bordered">
                          <tbody>
                            <tr>
                              <th><strong>Jenis</strong></th>
                              <th><strong>Nama</strong></th>
                            </tr>
                            <tr v-for="(v,k) in (row.aka_alergi||[])" :key="k+'ob'">
                              <td>
                                <div class="result_tab">
                                  {{v.jenis||"-"}}
                                </div>
                              </td>
                              <td>
                                <div class="result_tab">
                                  {{v.name||"-"}}
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div class="form-row" v-else>
                      <div class="col-md-12">
                          <span>Tidak Ada Alergi</span>
                      </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="card mb-0">
                <div class="card-header bg_head_panel">
                  <h6 class="card-title font-weight-semibold">Obat Rutin</h6>
                </div>
                <div class="card-body p-3">
                  <div class="form-row" v-if="row.aka_has_obat_rutin == 'Y'">
                    <div class="col-md-12">
                      <div class="table-responsive">
                        <table class="table table-bordered">
                          <tbody>
                            <tr>
                              <td>
                                <div class="result_tab">
                                  <h4>Keterangan</h4>
                                  <p>{{row.aka_keterangan_obat_rutin||"-"}}</p>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div class="form-row" v-else>
                      <div class="col-md-12">
                          <span>Tidak Ada Alergi</span>
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="form-row mt-2">
            <div class="col-md-12">
              <div class="card mb-0">
                <div class="card-header bg_head_panel">
                  <h6 class="card-title font-weight-semibold">Tanda-Tanda Vital</h6>
                </div>
                <div class="card-body p-3">
                  <div class="form-row">
                    <div class="col-md-12">
                      <div class="table-responsive">
                        <table class="table table-bordered">
                          <tbody>
                            <tr>
                              <td>
                                <div class="result_tab">
                                  <h4>Tekanan Darah</h4>
                                  <p>{{row.aka_ttv_tekanan_darah_min}} / {{row.aka_ttv_tekanan_darah_max}} mmHG</p>
                                </div>
                              </td>
                              <td>
                                <div class="result_tab">
                                  <h4>Nadi</h4>
                                  <p>{{row.aka_ttv_nadi||"-"}}/mnt</p>
                                </div>
                              </td>
                              <td>
                                <div class="result_tab">
                                  <h4>Gula Darah</h4>
                                  <p>{{row.aka_ttv_gula_darah||"-"}}mg/dL</p>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <div class="result_tab">
                                  <h4>Pernafasan</h4>
                                  <p>{{row.aka_ttv_pernafasan||"-"}}x/mnt - Reguler</p>
                                </div>
                              </td>
                              <td>
                                <div class="result_tab">
                                  <h4>SPO2</h4>
                                  <p>{{row.aka_ttv_spo2||"-"}}%</p>
                                </div>
                              </td>
                              <td>
                                <div class="result_tab">
                                  <h4>Suhu</h4>
                                  <p>{{row.aka_ttv_suhu||"-"}}C</p>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <div class="result_tab">
                                  <h4>Berat Badan</h4>
                                  <p>{{row.aka_ttv_weight||"-"}}kg</p>
                                </div>
                              </td>
                              <td>
                                <div class="result_tab">
                                  <h4>Tinggi</h4>
                                  <p>{{row.aka_ttv_height||"-"}}Cm</p>
                                </div>
                              </td>
                              <td>
                                <div class="result_tab">
                                  <h4>Lingkar Kepala</h4>
                                  <p>{{row.aka_ttv_lingkar_kepala||"-"}}cm</p>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td v-if="row.ap_usia >= 2">
                                <div class="result_tab">
                                  <h4>BMI</h4>
                                  <p>{{row.aka_ttv_bmi}}m2</p>
                                </div>
                              </td>
                              <td v-if="row.ap_usia <= 15">
                                <div class="result_tab">
                                  <h4>Luas Permukaan Tubuh Anak</h4>
                                  <p>{{row.aka_ttv_luas_permukaan_anak}}kg/m2</p>
                                </div>
                              </td>
                              <td colspan="2">
                                <div class="result_tab">
                                  <h4>Kesadaran</h4>
                                  <p>{{row.mk_name||"-"}}</p>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="form-row mt-2" v-if="(row.ap_gender == 2||row.ap_gender == 0) && row.ap_usia >= 12 && row.ap_usia <= 60">
            <div class="col-md-12">
              <div class="card mb-0">
                <div class="card-header bg_head_panel">
                  <h6 class="card-title font-weight-semibold">Status Kehamilan</h6>
                </div>
                <div class="card-body p-3">
                  <div class="form-row">
                    <div class="col-md-12">
                      <div class="table-responsive">
                        <table class="table table-bordered">
                          <tbody>
                            <tr>
                              <td>
                                <div class="result_tab">
                                  <h4>Pasien Sedang Hamil</h4>
                                  <p>{{row.aka_is_hamil == 'Y' ? 'Ya' : 'Tidak'}}</p>
                                </div>
                              </td>
                              <td>
                                <div class="result_tab">
                                  <h4>Pasien Menyusui</h4>
                                  <p>{{row.aka_is_menyusui == 'Y' ? 'Ya' : 'Tidak'}}</p>
                                </div>
                              </td>
                              <td v-if="row.aka_is_hamil == 'Y'">
                                <div class="result_tab">
                                  <h4>Gravid</h4>
                                  <p>{{row.aka_gravid || "-"}}</p>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td v-if="row.aka_is_hamil == 'Y'">
                                <div class="result_tab">
                                  <h4>Para</h4>
                                  <p>{{row.aka_para || "-"}}</p>
                                </div>
                              </td>
                              <td v-if="row.aka_is_hamil == 'Y'">
                                <div class="result_tab">
                                  <h4>Abortus</h4>
                                  <p>{{row.aka_abortus || "-"}}</p>
                                </div>
                              </td>
                              <td v-if="row.aka_is_hamil == 'Y'">
                                <div class="result_tab">
                                  <h4>HPHT</h4>
                                  <p>{{row.aka_hpht | moment("DD MMM YYYY")}}</p>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td colspan="3" v-if="row.aka_is_hamil == 'Y'">
                                <div class="result_tab">
                                  <h4>HPL</h4>
                                  <p>{{row.aka_hpl | moment("DD MMM YYYY")}}</p>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="form-row mt-2">
            <div class="col-md-6">
              <div class="card mb-0">
                <div class="card-header bg_head_panel">
                  <h6 class="card-title font-weight-semibold">Status Ekonomi</h6>
                </div>
                <div class="card-body p-3">
                  <div class="form-row">
                    <div class="col-md-12">
                      <div class="table-responsive">
                        <table class="table table-bordered">
                          <tbody>
                            <tr>
                              <td>
                                <div class="result_tab">
                                  <h4>Pekerjaan Pasien</h4>
                                  <p v-if="row.aka_pekerjaan !== 8">{{row.mp_name||"-"}}</p>
                                  <p v-else>{{row.aka_pekerjaan_lainnya||"-"}}</p>
                                </div>
                              </td>
                              <td>
                                <div class="result_tab">
                                  <h4>Hubungan dengan Keluarga</h4>
                                  <p>{{getConfig('baikNoOpt',row.aka_hubungan_keluarga)||"-"}}</p>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="card mb-0">
                <div class="card-header bg_head_panel">
                  <h6 class="card-title font-weight-semibold">Status Psikologi</h6>
                </div>
                <div class="card-body p-3">
                  <div class="form-row">
                    <div class="col-md-12">
                      <div class="table-responsive">
                        <table class="table table-bordered">
                          <tbody>
                            <tr>
                              <td>
                                <div class="result_tab">
                                  <h4>Status Psikologis Pasien</h4>
                                  <p>{{row.aka_status_psikologi_text||"-"}}</p>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="form-row mt-2">
            <div class="col-md-12">
              <div class="card mb-0">
                <div class="card-header bg_head_panel">
                  <h6 class="card-title font-weight-semibold">Status Fungsional
                  </h6>
                </div>
                <div class="card-body p-3">
                  <div class="form-row">
                    <div class="col-md-12">
                      <div class="table-responsive">
                        <table class="table table-bordered">
                          <tbody>
                            <tr>
                              <td>
                                <div class="result_tab">
                                  <h4>Alat Bantu</h4>
                                  <p>{{row.aka_is_alat_bantu == 'Y' ? 'Ya' : 'Tidak'}}</p>
                                </div>
                              </td>
                              <td>
                                <div class="result_tab">
                                  <h4>Alat Bantu</h4>
                                  <p v-if="row.aka_is_alat_bantu == 'Y'">
                                  {{row.mab_name||"-"}}</p>
                                  <p v-else> - </p>
                                </div>
                              </td>
                              <td>
                                <div class="result_tab">
                                  <h4>ADL (Aktifitas Sehari-hari)</h4>
                                  <p>{{getConfig('adlStatus',row.aka_adl)||"-"}}</p>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div :class="activeTab == 2 ? 'tab-pane fade show active' : 'tab-pane'" id="kajian-nyeri">
          <div class="row g-2">
            <!-- Skala Nyeri jika pasien berusia lebih dari sama dengan 6 tahun -->
            <div class="col-12" v-if="row.ap_usia > 6">
              <div class="row align-items-center">
                <div class="col-md">
                  <div>
                    <label for="">Skala Nyeri</label>
                      <div class="pain-scale no-pain" v-if="row.akn_skala_nyeri_value == 0">
                        <img :src="$parent.$parent.$parent.assetLocal('global_assets/images/pain-scale/1-no-pain.png')" alt="Pain Scale" width="80" height="80">
                        <strong>Tidak Ada Nyeri</strong>
                      </div>
                      <div class="pain-scale mild-pain" v-if="row.akn_skala_nyeri_value >= 1 && row.akn_skala_nyeri_value <= 3">
                          <img :src="$parent.$parent.$parent.assetLocal('global_assets/images/pain-scale/2-mild-pain.png')" alt="Pain Scale" width="80" height="80">
                          <strong>Sedikit Nyeri</strong>
                      </div>
                      <div class="pain-scale moderate-pain" v-if="row.akn_skala_nyeri_value >= 4 && row.akn_skala_nyeri_value <= 5">
                          <img :src="$parent.$parent.$parent.assetLocal('global_assets/images/pain-scale/3-moderate-pain.png')" alt="Pain Scale" width="80" height="80">
                          <strong>Agak Mengganggu</strong>
                      </div>
                      <div class="pain-scale severe-pain" v-if="row.akn_skala_nyeri_value >= 6 && row.akn_skala_nyeri_value <= 7">
                          <img :src="$parent.$parent.$parent.assetLocal('global_assets/images/pain-scale/4-severe-pain.png')" alt="Pain Scale" width="80" height="80">
                          <strong>Mengganggu Aktivitas</strong>
                      </div>
                      <div class="pain-scale worst-pain" v-if="row.akn_skala_nyeri_value >= 8 && row.akn_skala_nyeri_value <= 9">
                          <img :src="$parent.$parent.$parent.assetLocal('global_assets/images/pain-scale/5-very-severe.png')" alt="Pain Scale" width="80" height="80">
                          <strong>Sangat Mengganggu</strong>
                      </div>
                      <div class="pain-scale worst-pain" v-if="row.akn_skala_nyeri_value >= 10">
                          <img :src="$parent.$parent.$parent.assetLocal('global_assets/images/pain-scale/6-worst.png')" alt="Pain Scale" width="80" height="80">
                          <strong>Tak Tertahankan</strong>
                      </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Skala Nyeri jika pasien berusia kurang dari 6 tahun -->
            <div class="col-12" v-else>
              <div class="form-group">
                <label for="kidPainScale">Skala Nyeri<strong class="text-danger">*</strong></label>
                <table class="table table-bordered pain-scale-table">
                  <thead>
                    <tr>
                      <th>Pengkajian</th>
                      <th>Hasil</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(v,k) in row.akn_skala_nyeri" :key="k+'akn'">
                      <th class="table-warning text-center"><img :src="$parent.$parent.$parent.assetLocal(v.img)"
                        style="width:60px; height:60px;" alt="Pain Scale" width="80" height="80"><br>
                        {{v.name}}</th>
                      <td>
                        {{getConfigDynamic(v.configVal,v.value)||"-"}}
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr class="table-info">
                      <td colspan="4" class="text-info-700">
                          <span>NILAI SKALA NYERI: {{row.akn_skala_nyeri_value||"-"}}</span>
                          <span v-if="row.akn_skala_nyeri_value == 0" class="border-left ml-2 pl-2 font-weight-semibold">Nyaman</span>
                          <span v-else-if="row.akn_skala_nyeri_value >= 1 && row.akn_skala_nyeri_value < 4" class="border-left ml-2 pl-2 font-weight-semibold">Kurang Nyaman</span>
                          <span v-else-if="row.akn_skala_nyeri_value >= 4 && row.akn_skala_nyeri_value < 7" class="border-left ml-2 pl-2 font-weight-semibold">Nyeri Sedang</span>
                          <span v-else class="border-left ml-2 pl-2 font-weight-semibold">Nyeri Berat</span>
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div :class="activeTab == 3 ? 'tab-pane fade show active' : 'tab-pane'" id="risiko-jatuh">
          <div class="row">
            <div class="col-md-12">
              <table class="table table-bordered table-striped table-hover table-sm patient-table">
                <thead>
                  <tr>
                    <th>PARAMETER</th>
                    <th colspan="2">SKOR</th>
                  </tr>
                </thead>
                <tbody>
                  <template v-for="(v,k) in (row.arj_getup_go||[])" >
                      <tr :key="k+'arj'" v-if="v.value">
                      <td>
                          {{v.name||"-"}}
                      </td>
                      <td colspan="2">{{v.value||"-"}}</td>
                      </tr>
                  </template>
                  <tr v-if="!countRj">
                      <td colspan="99" class="text-center">Tidak Ada Data Resiko Jatuh</td>
                  </tr>
                  
                  <tr>
                      <th>SKALA: </th>
                      <th class="table-danger">{{row.arj_value||"-"}}</th>                  
                      <th class="table-danger" v-if="row.arj_value < 4">RENDAH</th>
                      <th class="table-danger" v-else>TINGGI</th>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          
          <div class="row mt-2 mb-2">
              <div class="col-md-12 mt-2">
                <table class="table table-bordered table-striped table-hover table-sm patient-table">
                    <thead>
                    <tr>
                        <th width="50%">HASIL</th>
                        <th width="50%">TINDAKAN</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(v,k) in (row.arj_tindakan_tambahan||[])" :key="k+'tindakan'">
                        <td v-if="k==0" :rowspan="(row.arj_tindakan_tambahan||[]).length">{{row.arj_hasil||"-"}}</td>
                        <td>{{v||"-"}}</td>
                    </tr>
                    </tbody>
                </table>
              </div>
          </div>
        </div>
        <div :class="activeTab == 4 ? 'tab-pane fade show active' : 'tab-pane'" id="skrining-gizi">
          <template v-if="row.arm_is_to_gizi == 'Y'">
            <div class="row" v-if="row.ap_usia < 15">
              <div class="col-md-12">
                <table class="table table-bordered table-striped table-hover table-sm patient-table">
                  <thead>
                    <tr>
                      <th>PARAMETER</th>
                    </tr>
                  </thead>
                  <tbody>
                    <template v-for="(v,k) in (row.asg_data||[])">
                        <tr :key="k+'asg'" v-if="v.value">
                            <td>{{v.name}}</td>
                        </tr>
                    </template>
                    <tr v-if="!countSkrining">
                        <td colspan="99" class="text-center">Tidak Ada Data Skrining Gizi</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!--
              <div class="col-12">
                <div class="alert alert-info">Sudah dibaca dan diketahui oleh ahli gizi tanggal: <span
                  class="font-weight-semibold">23 Mar 2023 17:12</span></div>
              </div>
              -->
            </div>
            <!-- v-else -->
            <div class="row" v-else>
              <div class="col-md-12">
                <h5 class="font-weight-semibold">Apakah Pasien Mengalami Penurunan Berat Badan yang Tidak Diinginkan
                  Selama 6 Bulan Terakhir?<strong class="text-danger">*</strong>
                </h5>
                <table class="table table-bordered table-striped table-hover table-sm">
                  <thead>
                    <tr>
                      <th>PARAMETER</th>
                      <th colspan="2">SKOR</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(v,k) in mSkrininGizi" :key="k+'gz'">
                        <td v-if="row.asg_value_label == v.idVal">
                        <div class="form-check form-check-inline">
                            <label class="form-check-label">
                                {{v.name}}
                            </label>
                        </div>
                        </td>
                        <td colspan="2" v-if="row.asg_value_label == v.idVal">{{v.valueVal}}</td>
                    </tr>

                    <tr class="table-secondary">
                        <td colspan="3">
                        <div class="form-check form-check-inline">
                            <label class="form-check-label">
                            Ya, dengan penurunan berat
                            </label>
                        </div>
                        </td>
                    </tr>

                    
                    <tr v-for="(v,k) in mSkrininGiziV2" :key="k+'b'">
                        <td v-if="row.asg_value_label == v.idVal">
                        <div class="form-check form-check-inline">
                            <label class="form-check-label">
                                {{v.name}}
                            </label>
                        </div>
                        </td>
                        <td v-if="row.asg_value_label == v.idVal">{{v.valueVal}}</td>
                    </tr>
                    
                    <tr>
                        <th>SKOR: </th>
                        <th class="table-danger">{{row.asg_value}}</th>
                        <th class="table-danger">{{row.asg_value > 1 ? 'TINGGI' : 'RENDAH'}}</th>
                    </tr>

                  </tbody>
                </table>
              </div>
              <div class="col-12 mt-2">
                <div class="table-responsive">
                  <table class="table table-bordered">
                    <tbody>
                      <tr>
                        <td>
                          <div class="result_tab">
                            <h4>Apakah asupan makanan berkurang karena tidak nafsu makan?</h4>
                            <p>{{row.asg_is_nafsu_makan == 'Y' ? 'Ya' : 'Tidak'}}</p>
                          </div>
                        </td>
                        <td>
                          <div class="result_tab">
                            <h4>Diagnosis Khusus Pasien</h4>
                            <p v-if="row.asg_diagnosis_khusus == 'L'">{{row.asg_diagnosis_khusus_lainnya || "-"}}</p>
                            <p v-else>{{row.asg_diagnosis_khusus || "-"}}</p>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </template>
          <div class="form-row" v-else>
              <div class="col-md-12 text-center">
                  <span>Tidak Ada Data</span>
              </div>
          </div>
        </div>
        <div :class="activeTab == 5 ? 'tab-pane fade show active' : 'tab-pane'" id="asuhan-keperawatan">
          <div class="form-row">
            <div class="col-md-12">
              <div class="card border shadow-0">
                <div class="card-header bg-info">
                  <h5 class="card-title font-weight-semibold">Standar Diagnosis Keperawatan Indonesia (SDKI)</h5>
                </div>
                <div class="card-body">
                  <ul class="nav nav-tabs nav-tabs-bottom">
                      <li class="nav-item" v-for="(v,k) in diagnosa" :key="k">
                          <a href="javascript:;" @click="changeTabSDKI(k+1)" data-toggle="tab" 
                          :data-target="'#diagTab'+(k+1)" 
                          :class="activeTabSDKI == (k+1) ? 'nav-link active' : 'nav-link'">
                          {{v.msd_code + " - " +v.msd_name||"Diagnosa baru"}}                
                          </a>
                      </li>
                  </ul>
                  <div class="tab-content card-body p-0">
                    <div v-for="(v,k) in diagnosa" :key="k+'a'" 
                      :class="activeTabSDKI == (k+1) ? 'tab-pane fade show active':'tab-pane fade'" :id="'#diagTab'+(k+1)">
                      <div class="row">
                        <div class="col-md-12">
                          <div class="table-responsive">
                            <table class="table table-bordered">
                              <tbody>
                                <tr>
                                  <td>
                                    <div class="result_tab">
                                      <h4>Diagnosa</h4>
                                      <p>{{v.msd_code ? v.msd_code + ' - ' + v.msd_name : '-'}}</p>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      <div class="form-row mt-2">
                        <div class="col-md-6">
                          <div class="card mb-0">
                            <div class="card-header bg_head_panel">
                              <h6 class="card-title font-weight-semibold">Penyebab</h6>
                            </div>
                            <div class="card-body p-3">
                              <div class="form-row">
                                <div class="col-md-12">
                                  <template v-for="(v,k) in (diagnosa[k].aaksd_penyebab||[])">
                                      <div :key="k+'penyebab'" class="d-flex align-items-center  mb-2">
                                      <i class="icon-checkmark-circle text-success"></i>
                                      <span class="ml-1">{{v}}</span>
                                      </div>
                                  </template>
                                  <p v-if="!(diagnosa[k].aaksd_penyebab||[]).length">Tidak Ada</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        
                        <div class="col-md-6">
                          <div class="card mb-0">
                            <div class="card-header bg_head_panel">
                              <h6 class="card-title font-weight-semibold">Faktor Resiko</h6>
                            </div>
                            <div class="card-body p-3">
                              <div class="form-row">
                                <div class="col-md-6">
                                  <template v-for="(v,k) in (diagnosa[k].aaksd_faktor_resiko||[])">
                                      <div :key="k+'faktor-res'" class="d-flex align-items-center  mb-2">
                                      <i class="icon-checkmark-circle text-success"></i>
                                      <span class="ml-1">{{v}}</span>
                                      </div>
                                  </template>
                                  <p v-if="!(diagnosa[k].aaksd_faktor_resiko||[]).length">Tidak Ada</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="col-md-6">
                          <div class="card mb-0">
                            <div class="card-header bg_head_panel">
                              <h6 class="card-title font-weight-semibold">Gejala dan Tanda-tanda Mayor</h6>
                            </div>
                            <div class="card-body p-3">
                              <div class="form-row">
                                <div class="col-md-6">
                                  <h6 class="font-weight-semibold">Subjective</h6>
                                  <template v-for="(v,k) in (diagnosa[k].aaksd_gejala_mayor_subjektif||[])">
                                      <div :key="k+'penyebab'" class="d-flex align-items-center  mb-2">
                                      <i class="icon-checkmark-circle text-success"></i>
                                      <span class="ml-1">{{v}}</span>
                                      </div>
                                  </template>
                                  <p v-if="!(diagnosa[k].aaksd_gejala_mayor_subjektif||[]).length">Tidak Ada</p>
                                </div>
                                <div class="col-md-6">
                                  <h6 class="font-weight-semibold">Objective</h6>
                                  <template v-for="(v,k) in (diagnosa[k].aaksd_gejala_mayor_objektif||[])">
                                      <div :key="k+'penyebab'" class="d-flex align-items-center  mb-2">
                                      <i class="icon-checkmark-circle text-success"></i>
                                      <span class="ml-1">{{v}}</span>
                                      </div>
                                  </template>
                                  <p v-if="!(diagnosa[k].aaksd_gejala_mayor_objektif||[]).length">Tidak Ada</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="card mb-0">
                            <div class="card-header bg_head_panel">
                              <h6 class="card-title font-weight-semibold">Gejala dan Tanda-tanda Minor</h6>
                            </div>
                            <div class="card-body p-3">
                              <div class="form-row">
                                <div class="col-md-6">
                                  <h6 class="font-weight-semibold">Subjective</h6>
                                  <template v-for="(v,k) in (diagnosa[k].aaksd_gejala_minor_subjektif||[])">
                                      <div :key="k+'penyebab'" class="d-flex align-items-center  mb-2">
                                      <i class="icon-checkmark-circle text-success"></i>
                                      <span class="ml-1">{{v}}</span>
                                      </div>
                                  </template>
                                  <p v-if="!(diagnosa[k].aaksd_gejala_minor_subjektif||[]).length">Tidak Ada</p>
                                </div>
                                <div class="col-md-6">
                                  <h6 class="font-weight-semibold">Objective</h6>
                                  <template v-for="(v,k) in (diagnosa[k].aaksd_gejala_minor_objektif||[])">
                                      <div :key="k+'penyebab'" class="d-flex align-items-center  mb-2">
                                      <i class="icon-checkmark-circle text-success"></i>
                                      <span class="ml-1">{{v}}</span>
                                      </div>
                                  </template>
                                  <p v-if="!(diagnosa[k].aaksd_gejala_minor_objektif||[]).length">Tidak Ada</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="col-md-12">
                          <div class="card mb-0">
                            <div class="card-header bg_head_panel">
                              <h6 class="card-title font-weight-semibold">Standar Luaran Keperawatan Indonesia
                                (SLKI)
                              </h6>
                            </div>
                            <div class="card-body p-3">
                              <div class="form-row">
                                <div class="col-md-12">
                                  <ul class="nav nav-tabs nav-tabs-bottom">
                                      <li class="nav-item" v-for="(v1,k1) in v.slki" :key="'Luaran'+k1">
                                      <a href="javascript:;" @click="changeTabSLKI(k1+1,k)" data-toggle="tab" 
                                      :data-target="'#luarTab'+(k1+1)" :class="v.aaksd_last_tab_slki == (k1+1) ? 'nav-link active' : 'nav-link'">
                                      {{  v1.msl_code + " - " + v1.msl_name || "Luaran Baru"}}</a>
                                      </li>
                                  </ul>
                                  <div class="tab-content card-body p-0">
                                    <div v-for="(v1,k1) in v.slki" :key="k1+'lu'" :class="v.aaksd_last_tab_slki == (k1+1) ? 'tab-pane fade show active':'tab-pane fade'"  :id="'#luarTab'+(k1+1)">
                                      <div class="row">
                                        <div class="col-md-12">
                                          <div class="table-responsive">
                                            <table class="table table-bordered">
                                              <tbody>
                                                <tr>
                                                  <td>
                                                    <div class="result_tab">
                                                      <h4> Luaran</h4>
                                                      <p>{{v1.msl_code ? v1.msl_code + ' - ' + v1.msl_name : '-'}}</p>
                                                    </div>
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </div>
                                        </div>
                                        <div class="col-md-12">
                                          <table
                                            class="table table-bordered table-striped table-hover table-sm patient-table">
                                            <thead>
                                              <tr>
                                                <th>Kriteria</th>
                                                <th>Skor</th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              <tr v-for="(v11,k11) in (v1.aaksl_kriteria_hasil||[])" :key="k11+'kh'">
                                                <td>
                                                  {{v11.name||"-"}}
                                                </td>
                                                <td>{{v11.hasil||"-"}}</td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-12">
                          <div class="card mb-0">
                            <div class="card-header bg_head_panel">
                              <h6 class="card-title font-weight-semibold">Standar Intervensi Keperawatan Indonesia
                                (SIKI)
                              </h6>
                            </div>
                            <div class="card-body p-3">
                              <div class="form-row">
                                <div class="col-md-12">
                                  <ul class="nav nav-tabs nav-tabs-bottom">
                                          
                                      <li class="nav-item" v-for="(v2,k2) in v.siki" :key="'Intervensi'+k2">
                                      <a href="javascript:;" @click="changeTabSIKI(k2+1,k)" data-toggle="tab" 
                                      :data-target="'#intvTab'+(k2+1)" :class="v.aaksd_last_tab_siki == (k2+1) ? 'nav-link active' : 'nav-link'">
                                      {{ v2.msi_code + " - " +v2.msi_name || "Intervensi Baru"}}
                                      </a>
                                      </li>
                                  </ul>
                                  <div class="tab-content card-body p-0">
                                    <div v-for="(v2,k2) in v.siki" :key="k2+'sik'" :class="v.aaksd_last_tab_siki == (k2+1) ? 'tab-pane fade show active':'tab-pane fade'" :id="'#intvTab1'+(k2+1)">
                                      <div class="row">
                                        <div class="col-md-12">
                                          <div class="table-responsive">
                                            <table class="table table-bordered">
                                              <tbody>
                                                <tr>
                                                  <td>
                                                    <div class="result_tab">
                                                      <h4> Intervensi</h4>
                                                      <p>{{v2.msi_code ? v2.msi_code + ' - ' + v2.msi_name : '-'}}</p>
                                                    </div>
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </table>
                                          </div>
                                        </div>
                                        <div class="col-md-12">
                                          <table
                                            class="table table-bordered table-striped table-hover table-sm patient-table">
                                              <thead>
                                                  <tr>
                                                  <th class="table-primary" colspan="99">Tindakan</th>
                                                  </tr>
                                              </thead>
                                              <tbody>
                                                  <tr>
                                                  <th colspan="2" class="table-light bg_head_panel">OBSERVASI</th>
                                                  </tr>
                                                  <template v-for="(vo,ko) in diagnosa[k]['siki'][k2]['aaksi_tindakan_observasi']" >
                                                      <tr :key="ko+'ko'" v-if="vo.value">
                                                          <td colspan="2">{{vo.name}}</td>
                                                      </tr>
                                                  </template>
                                                  <tr v-if="!countTindakan(diagnosa[k]['siki'][k2]['aaksi_tindakan_observasi'])">
                                                      <td colspan="99" class="text-danger">Tidak Ada Tindakan</td>
                                                  </tr>
                                              </tbody>
                                              <tbody>
                                                  <tr>
                                                  <th colspan="2" class="table-light bg_head_panel">TERAPEUTIK</th>
                                                  </tr>
                                                  <template v-for="(vt,kt) in diagnosa[k]['siki'][k2]['aaksi_tindakan_terapeutik']" >
                                                      <tr :key="kt+'kt'" v-if="vt.value">
                                                          <td colspan="2">{{vt.name}}</td>
                                                      </tr>
                                                  </template>
                                                  <tr v-if="!countTindakan(diagnosa[k]['siki'][k2]['aaksi_tindakan_terapeutik'])">
                                                      <td colspan="99" class="text-danger">Tidak Ada Tindakan</td>
                                                  </tr>
                                              </tbody>
                                              
                                              <tbody>
                                                  <tr>
                                                  <th colspan="2" class="table-light bg_head_panel">EDUKASI</th>
                                                  </tr>
                                                  <template v-for="(ve,ke) in diagnosa[k]['siki'][k2]['aaksi_tindakan_edukasi']" >
                                                      <tr :key="ke+'ke'" v-if="ve.value">
                                                          <td colspan="2">{{ve.name}}</td>
                                                      </tr>
                                                  </template>
                                                  <tr v-if="!countTindakan(diagnosa[k]['siki'][k2]['aaksi_tindakan_edukasi'])">
                                                      <td colspan="99" class="text-danger">Tidak Ada Tindakan</td>
                                                  </tr>
                                                  
                                              </tbody>
                                              
                                              <tbody>
                                                  <tr>
                                                  <th colspan="2" class="table-light bg_head_panel">KOLABORASI</th>
                                                  </tr>
                                                  <template v-for="(vk,kk) in diagnosa[k]['siki'][k2]['aaksi_tindakan_kolaborasi']" >
                                                      <tr :key="kk+'kk'" v-if="vk.value">
                                                          <td colspan="2">{{vk.name}}</td>
                                                      </tr>
                                                  </template>
                                                  <tr v-if="!countTindakan(diagnosa[k]['siki'][k2]['aaksi_tindakan_kolaborasi'])">
                                                      <td colspan="99" class="text-danger">Tidak Ada Tindakan</td>
                                                  </tr>
                                              </tbody>
                                          </table>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>         
                      <div class="card border shadow-0">
                          <div class="card-header bg_head_panel">
                              <h6 class="card-title font-weight-semibold">Tindakan Keperawatan</h6>
                          </div>
                          <div class="table-responsive">
                              <table class="table table-bordered">
                              <thead>
                                  <tr>
                                  <th>Diagnosa</th>
                                  <th>Tindakan Keperawatan</th>
                                  <th>PPA</th>
                                  <th>Waktu</th>
                                  <th>Tindakan</th>
                                  </tr>
                              </thead>
                              <tbody>
                                  <tr v-for="(vti,kti) in (v.aaksd_tindakan_keperawatan||[])" :key="kti+'tper'">
                                      <td v-if="kti == 0" :rowspan="(v.aaksd_tindakan_keperawatan||[]).length">Nyeri Akut</td>
                                      <td>{{vti.name||"-"}}</td>
                                      <td>
                                        <span>{{vti.ppa||"-"}}</span><br/>
                                        <span>{{vti.ppaLainnya||"-"}}</span>
                                      </td>
                                      <td>
                                          <span v-if="vti.dates">
                                              {{vti.dates | moment("DD MMM YYYY")}}
                                              <span v-if="vti.time" class="ml-2">
                                                  {{vti.time.HH}}:{{vti.time.mm}}
                                              </span>
                                          </span>
                                          <span v-else> - </span>
                                      </td>
                                      <td>{{vti.followup||"-"}}</td>
                                  </tr>
                                  <tr v-if="(v.aaksd_tindakan_keperawatan||[]).length">
                                      <td> - </td>
                                      <td>{{v.aaksd_informasi_tindakan||"-"}}</td>
                                      <td>      
                                          <span>Perawat</span>
                                          <span>{{v.aaksd_nama_perawat||""||"-"}}</span>
                                      </td>
                                      <td>
                                          <span>
                                              {{row.dates | moment("DD MMM YYYY")}}
                                              <span class="ml-2">
                                                  {{v.aaksd_tindakan_pada | moment("h:mm") }}
                                              </span>
                                          </span>
                                      </td>
                                      <td>
                                          {{v.aaksd_follow_up||"-"}}
                                      </td>
                                  </tr>
                                  <tr v-if="!(v.aaksd_tindakan_keperawatan||[]).length">
                                      <td colspan="99">Tidak Ada Tindakan Keperawatan</td>
                                  </tr>
                              </tbody>
                              </table>
                          </div>
                      </div>
                      
                    </div>
                  </div>
                </div>
              </div>
              
              <div class="card border shadow-0">
                <div class="card-header bg-info">
                  <h5 class="card-title font-weight-semibold">Tindak Lanjut Poli</h5>
                </div>
                <div class="card-body">
                  <div class="table-responsive">
                    <table class="table table-bordered">
                      <tbody>
                        <tr>
                          <td>
                            <div class="result_tab">
                              <h4>Apakah pasien akan dipindahkan ke poli lain?</h4>
                              <p>{{row.arm_is_move_poli == 'Y'?'Ya':'Tidak'}}</p>
                            </div>
                          </td>
                          <td>
                            <div class="result_tab">
                              <h4>Poli</h4>
                              <p>{{row.poli||"-"}}</p>
                            </div>
                          </td>
                          <td>
                            <div class="result_tab">
                              <h4>Dokter</h4>
                              <p>{{row.dokter||"-"}}</p>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>

import $ from 'jquery'
const _ = global._
import Gen from '@/libs/Gen.js'
const moment = require('moment')

export default{
    props:{
        row:Object,
        rowReg:Object,
        mrValidation:Object,
        mPekerjaan:Array,
        mAlatBantu:Array,
        mSkrininGizi:Array,
        mSkrininGiziV2:Array,
        diagnosa:Array,
        mSDKI: Array,
        mSLKI: Array,
        mSIKI: Array,
        mPoli: Array,
        mDokter: Array,
        Config:Object,
        mKesadaran: Array,
        historyKajianPerawat: Array
    },
    computed: {
        countRj(){
            let total = 0
            for(let i = 0; i < (this.row.arj_getup_go||[]).length; i++){
                if(this.row.arj_getup_go[i]['value']){
                    total += 1
                }
            }
            return total
        },
        countSkrining(){
            let total = 0
            for(let i = 0; i < (this.row.asg_data||[]).length; i++){
                if(this.row.asg_data[i]['value']){
                    total += 1
                }
            }
            return total
        }
    },
    data(){
        return {
            activeTab : 1,
            activeTabSDKI : 1
        }
    },
    methods: {
        changeKajianAwal(e){
          this.$parent.$parent.$parent.toDetail({kajianPerawatId : e})
        },
        countTindakan(data){
            let total = 0
            for(let i = 0; i < (data||[]).length; i++){
                if(data[i]['value']){
                    total += 1
                }
            }
            return total
        },
        getConfig(mrName,value){
            let text = ''
            if(value){
                let index = this.Config.mr[mrName].findIndex(x => x.value == value)
                if(index !== -1){
                    text = this.Config.mr[mrName][index]['text']
                }
            }
            return text
        },
        getMaster(mrName,value){
            let text = ''
            if(value){
                let index = this[mrName].findIndex(x => x.value == value)
                if(index !== -1){
                    text = this[mrName][index]['text']
                }
            }
            return text
        },
        getConfigDynamic(master,value){
            let text = ''
            if(value){
                let index = master.findIndex(x => x.value == value)
                if(index !== -1){
                    text = master[index]['text']
                }
            }
            return text
        },

        otherConditional(){
            let v = this.row
            return v.arm_created_by == this.$parent.$parent.$parent.user.id && moment().format('YYYY-MM-DD') <= this.to24Hours(v.arm_created_date)
        },
        to24Hours(date){
            return moment(date).add(48, 'hours').format('YYYY-MM-DD')
        },
        changeTab(e){
            this.activeTab = e
        },
        toList(){
            this.$router.push({ name: 'RekamMedis', params: { pageSlug: this.row.arm_pasien_id }, query: {regId: this.row.arm_ar_id} }).catch(()=>{}) 
        },
        toDetail(){
            let v = this.row
            this.$router.push({ name: 'RekamMedis', params: { pageSlug: this.row.ap_id, rmNo: v.arm_id,
            typeKajian:v.arm_is_kajian_awal == 'Y' ? 'awal' : 'lanjutan' }, query: {regId: this.$route.query.regId} }).catch(()=>{})
        },
        

        changeTabSDKI(k){
            this.activeTabSDKI = k
        },

        changeTabSLKI(k1,k){
            this.diagnosa[k].aaksd_last_tab_slki = k1
        },
        
        changeTabSIKI(k2,k){
            this.diagnosa[k].aaksd_last_tab_siki = k2
        },
    }
}

</script>
